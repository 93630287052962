export default function Hero() {
  return (
    <section className="p-4 py-11">
      <article className="flex flex-col gap-8 items-center text-center w-full max-w-4xl m-auto text-lg md:text-2xl text-secondary">
        <article className="flex flex-col gap-7 items-center w-full">
          <h1 className="text-secondary font-bold text-3xl md:text-5xl">
            And you’ll still receive{" "}
            <mark className="text-primary bg-transparent font-bold">
              2 Surprises!!!
            </mark>
          </h1>

          <a
            href="https://checkout.payt.com.br/22ced79030992233449d6c1e0cea47df"
            className="button pulse-animation regular-shadow"
          >
            I WANT THE BEER APP WITH 30% DISCOUNT
          </a>
        </article>

        <article className="flex flex-col gap-4">
          <h2 className="font-bold text-2xl md:text-4xl">
            Eliminate that belly now!
          </h2>

          <h3 className="font-bold">If you want to…</h3>

          <p>…Get rid of that annoying fat on your belly and sides…</p>

          <p>…Feel good about yourself when you look in the mirror…</p>

          <p>
            …No longer be the subject of jokes among family and friends for
            being overweight…
          </p>

          <p>And go back to being that confident and attractive man…</p>

          <h4 className="font-bold">Then…</h4>

          <h5 className="font-bold">
            The <mark className="text-primary bg-transparent">BEER APP</mark> is
            for you!
          </h5>

          <p>
            The only solution capable of tackling the root cause of your excess
            weight…
          </p>

          <p>
            …And making you burn up to 10 kg of fat in{" "}
            <mark className="font-bold bg-transparent underline text-secondary">
              just 21 days…
            </mark>
          </p>

          <p>
            Without needing to resort to{" "}
            <mark className="font-bold bg-transparent underline text-secondary">
              restrictive diets, exercises, fake recipes, or costly supplements…
            </mark>
          </p>
        </article>

        <article className="flex flex-col gap-4 w-full items-center">
          <h2 className="font-bold text-primary text-2xl md:text-4xl">
            THE DECISION IS IN YOUR HANDS!
          </h2>

          <p>
            The decision that will be responsible for your body transformation!
          </p>

          <p>
            Previously{" "}
            <mark className="bg-transparent line-through text-primary">
              $97.00
            </mark>
            , now for just{" "}
            <mark className="font-bold bg-transparent text-secondary">
              only{" "}
              <mark className="bg-green-600 text-white font-bold">$67.00</mark>
            </mark>
          </p>

          <a
            href="https://checkout.payt.com.br/22ced79030992233449d6c1e0cea47df"
            className="button pulse-animation regular-shadow mt-4"
          >
            I WANT THE BEER APP WITH 30% DISCOUNT
          </a>
        </article>

        {/* The rest of the component follows the same pattern */}
      </article>
    </section>
  );
}
